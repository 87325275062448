const contentData = [
  {
    title: "A place to hang out",
    // link: "https://auth0.com/docs/connections",
    description:
      "Omegabuild is a small and tight knit community, where players gather around for the means to chill"
  },
  {
    title: "A place to have fun",
    // link: "https://auth0.com/docs/multifactor-authentication",
    description:
      "Omegabuild offers many unique game modes such as Zombie Survival, parkour, dropper, maze, etc."
  },
  {
    title: "A place to build",
    // link: "https://auth0.com/docs/anomaly-detection",
    description:
      "With out various building modes, such as freebuild (flat build, pixelart), and personal Overseer maps, you can build cool maps using our wide selection of blocks and share it with others!"
  },
  {
    title: "A place to compete",
    // link: "https://auth0.com/docs/rules",
    description:
      "Our server offers a gamified system with currency and XP for all players, so to have an engaging experience with each completition of their own maps"
  }
];

export default contentData;
