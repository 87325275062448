import React, { Component } from "react";

import { CardColumns, Card, CardBody, CardTitle, CardSubtitle, CardText, ListGroup, ListGroupItem } from "reactstrap";

import staffList from "../utils/contactCardInfo";

class Content extends Component {
  render() {
    return (
      <div className="next-steps my-5">
        <h2 className="my-5 text-center">Our Staff Members</h2>
        <CardColumns>
          {staffList.map((col, i) => (
            <Card key={i} md={4}
            style={{
              width: '18rem'
            }}
          >
            <CardBody>
              <CardTitle tag="h5">
                {col.name}
              </CardTitle>
              <CardSubtitle
                className="mb-2 text-muted"
                tag="h6"
              >
                {col.rank}
              </CardSubtitle>
            </CardBody>
            {col.picture != null && (
              <img
              alt={col.name + "'s image"}
              src={col.picture}
              width="100%"
            />
            )}
            <CardBody>
              <CardText>
                {col.description}
              </CardText>
              <ListGroup flush>
                {col.email != null && (
                  <ListGroupItem>
                    <strong>Email:</strong> {col.email}
                  </ListGroupItem>
                )}
                {col.discord != null && (
                  <ListGroupItem>
                    <strong>Discord:</strong> {col.discord}
                  </ListGroupItem>
                )}
              </ListGroup>
            </CardBody>
          </Card>
          ))}
        </CardColumns>
      </div>
    );
  }
}

export default Content;
