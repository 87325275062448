import React, { Fragment } from "react";

import StaffContact from "../components/StaffContact";

const Staff = () => (
  <Fragment>
    <StaffContact />
  </Fragment>
);

export default Staff;
