import React from "react";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <p>This website is temporary, since SpicyCombo is tring to learn how to write code :c</p>
    <p>
      (c) 2024 Omegabuild. Maintained by <a href="https://www.spicycombo.net">SpicyCombo</a>
    </p>
    <p>Visit the original website <a href="https://omegabuildcc.github.io">here</a></p>
  </footer>
);

export default Footer;
