import Scav from "../assets/Scav.png"
import SpicyCombo from "../assets/SpicyCombo.png"
import CounterTerrorist from "../assets/CounterTerrorist.png"


const contentData = [
    {
      name: "Scav",
      rank: "Owner",
      picture: Scav,
      description:
        "Since Omegabuild's inception, Scav has been dedicated to improving the overall experience of all Omegabuild players, who has sought up the majority of the gamemodes today.",
      email: "omegabuild.cc@gmail.com"
    },
    {
      name: "SpicyCombo",
      rank: "Developer",
      picture: SpicyCombo,
      description:
        "Once a former owner for around a year, and has gained mass experience in running his own server, SpicyCombo is an avid developer who writes codebases that power the Omegabuild server.",
      email: "spicycombo@omegabuild.uk"
    },
    {
      name: "CounterTerrorist",
      picture: CounterTerrorist,
      rank: "Senior Staff",
      description:
        "With his extreme dedication to staffing, CounterTerrorist has been a long time member of the Omegabuild staff team, enforcing many rules on the server.",
      discord: "blitzk_"
    },
    {
      name: "This could be you!",
      description:
        "With dedication and trust, in the future you can also apply for a staff role on the server!"
    }
  ];
  
  export default contentData;
  