import React from "react";

import logo from "../assets/omegabuild.svg";

const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3 app-logo" src={logo} alt="Omegabuild Logo" width="120" />
    <h1 className="mb-4">Welcome to omegabuild.uk</h1>

    <p className="lead">
      Omegabuild 2 is a ClassiCube server featuring unique gamemodes, including various minigames, realms, and more. Join today!
    </p>
  </div>
);

export default Hero;
